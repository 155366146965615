<template>
  <v-app>
    <v-app-bar
      app
      color="black"
      dark
      clipped-left
    >
      <v-row align="center">
        <v-img
          class="shrink mr-2"
          contain
          :src="require('@/assets/jinic_logo_white_small.png')"
          transition="scale-transition"
          alt="Jinic-Média"
          max-height="50px"
          max-width="76px"
        />
      </v-row>
      <v-spacer></v-spacer>
      <v-btn
        href="https://jinic-media.com"
        target="_blank"
        text
      >
        <span class="mr-2">Plus d'information sur nous</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <v-navigation-drawer
          permanent
          expand-on-hover
          clipped
          fixed
      >
        <v-list>
          <v-list-item class="px-2">
            <v-list-item-avatar>
              <v-avatar
                  color="primary"
                  size="56"
              >J-M</v-avatar>
            </v-list-item-avatar>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <v-list-item-title>App de test - Héritage Virtuel</v-list-item-title>
              <v-list-item-subtitle>WebGL in PWA</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list
            nav
            dense
        >
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-folder</v-icon>
            </v-list-item-icon>
            <v-list-item-title>My Files</v-list-item-title>
          </v-list-item>
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-account-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Shared with me</v-list-item-title>
          </v-list-item>
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-star</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Starred</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
