<template>
  <v-container fluid class="ma-0 pa-0">
    <v-dialog
        v-model="showDialog"
        width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2" v-if="countRemoved<3">
          Bravo ! plus que {{ 3 - countRemoved }}
        </v-card-title>
        <v-card-title v-else>Terminé!</v-card-title>
        <v-card-text>
          Démonsration d'intéraction de jeux rapide, on peut tout faire à partir de ça.
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="showDialog = false"
          >
            Ok trop bien !
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div id="scene-container" ref="scene-container"/>
  </v-container>
</template>

<script>
import {
  Scene,
  Color,
  PerspectiveCamera,
  Vector3,
  Raycaster,
  Vector2,
  DirectionalLight,
  HemisphereLight,
  AnimationMixer,
  Clock,
  WebGLRenderer,
  SphereGeometry,
  Matrix4,
  MeshBasicMaterial,
  ImageUtils,
  Mesh
} from "three";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";

export default {
  name: 'HelloWorld',
  data: () => ({
    container: null,
    scene: null,
    camera: null,
    renderer: null,
    controls: null,
    panoramasArray: [require('@/assets/img/00village02.jpg')],
    panoramaNumber: 0,
    mixers: [],
    objects: [],
    clock: null,
    savedX: 0,
    savedY: 0,
    savedLongitude: 0,
    savedLatitude: 0,
    raycaster: null,
    mouse: null,
    countRemoved: 0,
    showDialog: false

  }),
  mounted() {
    this.panoramaNumber = Math.floor(Math.random() * this.panoramasArray.length);
    this.init();
    window.addEventListener("resize", this.onWindowResize, false);
  },
  methods: {
    init() {
      this.container = document.getElementById("scene-container");
      console.log(document.getElementById('scene-container'));
      this.clock = new Clock();
      // Creating the scene
      this.scene = new Scene();
      this.scene.background = new Color("skyblue");
      this.raycaster = new Raycaster();
      this.mouse = new Vector2();
      this.createCamera();
      this.createLights();
      this.loadModels();
      this.createControls();
      this.createRenderer();
      this.create3DSphere();
      this.renderer.setAnimationLoop(() => {
        this.update();
        this.render();
      });
    },
    createCamera() {
      const fov = 35;
      const aspect = this.container.clientWidth / this.container.clientHeight;
      const near = 1;
      const far = 1000;
      this.camera = new PerspectiveCamera(fov, aspect, near, far);
      this.camera.position.set(-1.5, 1.5, 10);
      this.camera.target = new Vector3(0, 0, 0);
      document.addEventListener("mousedown", this.onDocumentMouseDown, false);
      document.addEventListener("mousemove", this.onDocumentMouseMove, false);
      document.addEventListener("mouseup", this.onDocumentMouseUp, false);
    },
    createLights() {
      const mainLight = new DirectionalLight(0xffffff, 5);
      mainLight.position.set(10, 10, 10);
      const hemisphereLight = new HemisphereLight(0xddeeff, 0x202020, 5);
      this.scene.add(mainLight, hemisphereLight);
    },
    create3DSphere() {
      // creation of a big sphere geometry
      let sphere = new SphereGeometry(200, 200, 80);
      sphere.applyMatrix(new Matrix4().makeScale(-1, 1, 1));
      // creation of the sphere material
      let sphereMaterial = new MeshBasicMaterial();
      sphereMaterial.map = ImageUtils.loadTexture(this.panoramasArray[this.panoramaNumber])
      // geometry + material = mesh (actual object)
      let sphereMesh = new Mesh(sphere, sphereMaterial);
      this.scene.add(sphereMesh);
    },
    loadModels() {
      const loader = new GLTFLoader();
      const onLoad = (result, position) => {
        const model = result.scene.children[0];
        model.position.copy(position);
        model.scale.set(0.15, 0.15, 0.15);

        const mixer = new AnimationMixer(model);
        this.mixers.push(mixer);
        const animation = result.animations[0];
        const action = mixer.clipAction(animation);
        action.play();
        this.scene.add(model);
        this.objects.push(model);
      };

      const onProgress = progress => {
        console.log(progress);
      };

      const parrotPosition = new Vector3(80, 50, -28.5);
      loader.load(
          './3d/Parrot.glb',
          gltf => onLoad(gltf, parrotPosition),
          onProgress
      );

      const flamingoPosition = new Vector3(38, 80, -100);
      loader.load(
          "./3d/Flamingo.glb",
          gltf => onLoad(gltf, flamingoPosition),
          onProgress
      );

      const storkPosition = new Vector3(50, 100, -100);
      loader.load(
          "./3d/Stork.glb",
          gltf => onLoad(gltf, storkPosition),
          onProgress
      );
    },
    onDocumentMouseDown(event) {
      event.preventDefault();
      this.manualControl = true;
      this.savedX = event.clientX;
      this.savedY = event.clientY;
      this.savedLongitude = this.longitude;
      this.savedLatitude = this.latitude;

      if (!this.showDialog) {
        var rect = this.renderer.domElement.getBoundingClientRect();
        this.mouse.x = ((event.clientX - rect.left) / (rect.width - rect.left)) * 2 - 1;
        this.mouse.y = -((event.clientY - rect.top) / (rect.bottom - rect.top)) * 2 + 1;
        this.raycaster.setFromCamera(this.mouse, this.camera);
        let intersects = this.raycaster.intersectObjects(this.objects);
        if (intersects.length > 0) {
          console.log(intersects);
          intersects.forEach((intersect) => {
            this.removeObject(intersect.object)
          })
        }
      }

    },
    // when the mouse moves, if in manual contro we adjust coordinates
    onDocumentMouseMove(event) {
      if (this.manualControl) {
        this.longitude = (this.savedX - event.clientX) * 0.1 + this.savedLongitude;
        this.latitude = (event.clientY - this.savedY) * 0.1 + this.savedLatitude;
      }
    },
    // when the mouse is released, we turn manual control off
    onDocumentMouseUp() {
      this.manualControl = false;
    },
    removeObject(object) {
      console.log(object)
      this.countRemoved++;
      this.showDialog = true;
      this.scene.remove(object);
    },
    createRenderer() {
      this.renderer = new WebGLRenderer({antialias: true});
      this.renderer.setSize(this.container.clientWidth, this.container.clientHeight);
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.physicallyCorrectLights = true;
      this.container.appendChild(this.renderer.domElement);
    },
    createControls() {
      this.controls = new OrbitControls(this.camera, this.container);
    },
    update() {
      const delta = this.clock.getDelta();
      this.mixers.forEach(mixer => mixer.update(delta));
    },
    render() {
      this.renderer.render(this.scene, this.camera);
    },
    onWindowResize() {
      this.camera.aspect = this.container.clientWidth / this.container.clientHeight;
      // Update camera frustum
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(this.container.clientWidth, this.container.clientHeight);
    }
  }
}
</script>
<style scoped>
#scene-container {
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>